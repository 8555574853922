/* eslint-disable unicorn/no-null -- Old code with lots of nulls */

import {
  type AbstractEngine,
  type ArcRotateCamera,
  type ICameraInput,
  KeyboardEventTypes,
  type KeyboardInfo,
  type Nullable,
  type Observer,
  PointerEventTypes,
  type PointerInfo,
  type Scene,
  serialize,
  Vector3,
} from '@babylonjs/core';
import { ok } from '@orangelv/utils';

import { getState } from '../state.js';
import type { Ref, State } from '../types.js';
import {
  type CameraState,
  transitionToGlobalView,
  transitionToOriginalView,
} from './camera-transitions.js';

const createEmptyCameraState = (): CameraState => ({
  target: null,
  radius: null,
  fov: null,
  upVector: null,
  hasRestoredOriginal: true,
});

const saveOriginalCameraState = (camera: ArcRotateCamera): CameraState => ({
  target: camera.target.clone(),
  radius: camera.radius,
  fov: camera.fov,
  upVector: camera.upVector.clone(),
  hasRestoredOriginal: true,
});

// https://github.com/BabylonJS/Babylon.js/blob/716b858f169e0ea830c1c92cf05263a6569dc2cb/packages/dev/core/src/Cameras/Inputs/arcRotateCameraKeyboardMoveInput.ts
export class CustomCameraKeyboardMoveInput
  implements ICameraInput<ArcRotateCamera>
{
  @serialize()
  public keysUp = [38];

  @serialize()
  public keysDown = [40];

  @serialize()
  public keysLeft = [37];

  @serialize()
  public keysRight = [39];

  @serialize()
  public keysReset = [220];

  @serialize()
  public panningSensibility = 50;

  @serialize()
  public angularSpeed = 0.01;

  public camera: Nullable<ArcRotateCamera> = null;
  private readonly keys = new Array<number>();
  private onCanvasBlurObserver: Nullable<Observer<AbstractEngine>> = null;
  private onKeyboardObserver: Nullable<Observer<KeyboardInfo>> = null;
  private engine: Nullable<AbstractEngine> = null;
  private scene: Nullable<Scene> = null;

  public attachControl(noPreventDefault?: boolean): void {
    if (this.onCanvasBlurObserver) {
      return;
    }

    ok(this.camera, 'Camera not set');

    this.scene = this.camera.getScene();
    this.engine = this.scene.getEngine();

    this.onCanvasBlurObserver = this.engine.onCanvasBlurObservable.add(() => {
      this.keys.length = 0;
    });

    this.onKeyboardObserver = this.scene.onKeyboardObservable.add((info) => {
      const { event } = info;
      // eslint-disable-next-line @typescript-eslint/no-deprecated -- This is how it's implemented upstream
      const { keyCode } = event;

      if (event.metaKey) {
        return;
      }

      if (info.type === KeyboardEventTypes.KEYDOWN) {
        if (
          this.keysUp.includes(keyCode) ||
          this.keysDown.includes(keyCode) ||
          this.keysLeft.includes(keyCode) ||
          this.keysRight.includes(keyCode) ||
          this.keysReset.includes(keyCode)
        ) {
          const index = this.keys.indexOf(keyCode);

          if (index === -1) {
            this.keys.push(keyCode);
          }

          if (!noPreventDefault) {
            event.preventDefault();
          }
        }
      } else if (
        this.keysUp.includes(keyCode) ||
        this.keysDown.includes(keyCode) ||
        this.keysLeft.includes(keyCode) ||
        this.keysRight.includes(keyCode) ||
        this.keysReset.includes(keyCode)
      ) {
        const index = this.keys.indexOf(keyCode);

        if (index !== -1) {
          this.keys.splice(index, 1);
        }

        if (!noPreventDefault) {
          event.preventDefault();
        }
      }
    });
  }

  public detachControl(): void {
    if (this.scene) {
      if (this.onKeyboardObserver) {
        this.scene.onKeyboardObservable.remove(this.onKeyboardObserver);
      }

      if (this.onCanvasBlurObserver && this.engine) {
        this.engine.onCanvasBlurObservable.remove(this.onCanvasBlurObserver);
      }

      this.onKeyboardObserver = null;
      this.onCanvasBlurObserver = null;
    }

    this.keys.length = 0;
  }

  public checkInputs(): void {
    if (this.onKeyboardObserver) {
      const { camera } = this;
      ok(camera, 'Camera not set');

      for (const keyCode of this.keys) {
        if (this.keysLeft.includes(keyCode)) {
          camera.inertialAlphaOffset -= this.angularSpeed;
        } else if (this.keysUp.includes(keyCode)) {
          camera.inertialBetaOffset -= this.angularSpeed;
        } else if (this.keysRight.includes(keyCode)) {
          camera.inertialAlphaOffset += this.angularSpeed;
        } else if (this.keysDown.includes(keyCode)) {
          camera.inertialBetaOffset += this.angularSpeed;
        } else if (this.keysReset.includes(keyCode)) {
          camera.restoreState();
        }
      }
    }
  }

  public getClassName(): string {
    return 'CustomCameraKeyboardMoveInput';
  }

  public getSimpleName(): string {
    return 'keyboard';
  }
}

// https://github.com/BabylonJS/Babylon.js/blob/716b858f169e0ea830c1c92cf05263a6569dc2cb/packages/dev/core/src/Cameras/Inputs/arcRotateCameraMouseWheelInput.ts
export class CustomCameraMouseWheelInput
  implements ICameraInput<ArcRotateCamera>
{
  @serialize()
  public wheelPrecision = 1;

  public camera: Nullable<ArcRotateCamera> = null;
  private pointerObserver: Nullable<Observer<PointerInfo>> = null;
  private originalState = createEmptyCameraState();
  private transitionState: 'none' | 'toGlobal' | 'toOriginal' = 'none';

  public constructor(private readonly stateRef: Ref<State>) {}

  public attachControl(noPreventDefault?: boolean): void {
    ok(this.camera, 'Camera not set');

    this.saveOriginalState();

    const scene = this.camera.getScene();
    this.pointerObserver = scene.onPointerObservable.add((info) => {
      if (info.type === PointerEventTypes.POINTERWHEEL) {
        const wheelDelta = (info.event as WheelEvent).deltaY > 0 ? -100 : 100;
        const delta = wheelDelta / (this.wheelPrecision * 40);
        ok(this.camera, 'Camera not set');
        this.camera.inertialRadiusOffset += delta;
      }

      if (!noPreventDefault) info.event.preventDefault();
    });
  }

  public detachControl(): void {
    if (this.pointerObserver) {
      ok(this.camera, 'Camera not set');
      this.camera.getScene().onPointerObservable.remove(this.pointerObserver);
      this.pointerObserver = null;
    }

    this.originalState = createEmptyCameraState();
    this.transitionState = 'none';
  }

  public checkInputs(): void {
    const { props } = getState(this.stateRef);
    // This means we're not using the new "advanced" camera setup, therefore do nothing
    if (!props.config.camera?.preserveRadiusFromBlender) return;

    ok(this.camera, 'Camera not set');
    ok(this.originalState.target);
    ok(this.originalState.radius !== null);
    ok(this.originalState.fov !== null);
    ok(this.originalState.upVector);

    const maxRadius =
      this.camera.upperRadiusLimit ?? this.originalState.radius * 2;
    const transitionThreshold = (this.originalState.radius + maxRadius) / 2;

    if (
      this.camera.radius > transitionThreshold &&
      this.originalState.hasRestoredOriginal &&
      this.transitionState === 'none'
    ) {
      this.transitionState = 'toGlobal';
      this.originalState.hasRestoredOriginal = false;
      transitionToGlobalView(
        this.camera,
        false, // We don't want to transition the radius because it's controlled by the user (by scrolling)
        () => {
          this.transitionState = 'none';
        },
      );
    } else if (
      this.camera.radius <= transitionThreshold &&
      !this.originalState.hasRestoredOriginal &&
      this.transitionState === 'none'
    ) {
      this.transitionState = 'toOriginal';

      transitionToOriginalView(this.camera, this.originalState, false, () => {
        this.originalState.hasRestoredOriginal = true;
        this.transitionState = 'none';
      });
    }
  }

  public getClassName(): string {
    return 'CustomCameraMouseWheelInput';
  }

  public getSimpleName(): string {
    // This must be `mousewheel`, otherwise `wheelPrecision` setter won't work.
    return 'mousewheel';
  }

  public saveOriginalState(): void {
    ok(this.camera, 'Camera not set');

    this.originalState = saveOriginalCameraState(this.camera);
  }
}

export class CustomCameraDragInput implements ICameraInput<ArcRotateCamera> {
  @serialize()
  // How large or small the dragging "radius" is, before the transition to global view
  public globalViewThreshold = 0.8;

  @serialize()
  public returnThresholdFactor = 0.6;

  public camera: Nullable<ArcRotateCamera> = null;
  private pointerObserver: Nullable<Observer<PointerInfo>> = null;
  private originalState = createEmptyCameraState();
  private initialCameraPosition = Vector3.Zero();
  private isInGlobalView = false;
  private isDragging = false;
  private isTransitioning = false;

  public constructor(private readonly stateRef: Ref<State>) {}

  public attachControl(noPreventDefault?: boolean): void {
    ok(this.camera, 'Camera not set');

    this.saveOriginalState();

    const scene = this.camera.getScene();
    this.pointerObserver = scene.onPointerObservable.add((info) => {
      if (!this.camera) return;

      // Prevent dragging while transitioning
      if (this.isTransitioning) {
        if (info.type === PointerEventTypes.POINTERDOWN) {
          info.event.preventDefault();
        }

        return;
      }

      if (
        info.type === PointerEventTypes.POINTERDOWN &&
        info.event.button === 0
      ) {
        this.isDragging = true;
        if (!noPreventDefault) info.event.preventDefault();
      } else if (info.type === PointerEventTypes.POINTERUP && this.isDragging) {
        this.isDragging = false;
        if (!noPreventDefault) info.event.preventDefault();
      }
    });
  }

  public detachControl(): void {
    if (this.pointerObserver) {
      ok(this.camera, 'Camera not set');
      this.camera.getScene().onPointerObservable.remove(this.pointerObserver);
      this.pointerObserver = null;
    }

    this.originalState = createEmptyCameraState();
    this.isDragging = false;
    this.isInGlobalView = false;
    this.isTransitioning = false;
  }

  public checkInputs(): void {
    if (!this.camera) return;

    const { props } = getState(this.stateRef);
    if (!props.config.camera) return;

    if (this.isTransitioning || !this.isDragging) return;

    ok(this.originalState.target);
    ok(this.originalState.radius !== null);
    ok(this.originalState.fov !== null);
    ok(this.originalState.upVector);

    const positionDistance = Vector3.Distance(
      this.initialCameraPosition,
      this.camera.position,
    );

    const globalThreshold = this.camera.radius * this.globalViewThreshold;
    const originalThreshold = this.camera.radius * this.returnThresholdFactor;

    if (!this.isInGlobalView && positionDistance > globalThreshold) {
      this.isDragging = false;
      this.isTransitioning = true;

      transitionToGlobalView(this.camera, true, () => {
        this.isInGlobalView = true;
        this.isTransitioning = false;
      });
    } else if (this.isInGlobalView && positionDistance < originalThreshold) {
      this.isDragging = false;
      this.isTransitioning = true;

      transitionToOriginalView(this.camera, this.originalState, true, () => {
        this.isInGlobalView = false;
        this.isTransitioning = false;
      });
    }
  }

  public getClassName(): string {
    return 'CustomCameraDragInput';
  }

  public getSimpleName(): string {
    return 'drag';
  }

  public saveOriginalState(): void {
    ok(this.camera, 'Camera not set');

    this.originalState = saveOriginalCameraState(this.camera);

    this.initialCameraPosition = this.camera.position.clone();
    this.isInGlobalView = false;
    this.isTransitioning = false;
  }
}
