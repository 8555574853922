import { type ArcRotateCamera, Matrix } from '@babylonjs/core';

import type { CameraConfig } from '../types.js';

const getTranslationValue = (
  value: number | undefined,
  denominator: number,
): number =>
  value === undefined ? 0
  : Number.isInteger(value) ? value / denominator
  : value;

export const applyCameraProjectionTranslation = (
  camera: ArcRotateCamera,
  translation: CameraConfig['translateProjection'],
): void => {
  if (
    !translation ||
    (translation.x === undefined && translation.y === undefined)
  ) {
    return;
  }

  const { x, y } = translation;
  const scene = camera.getScene();
  const engine = scene.getEngine();
  const width = engine.getRenderWidth();
  const height = engine.getRenderHeight();

  // Force recompute to avoid multiplying the matrix more than once
  const projectionMatrix = camera.getProjectionMatrix(true);
  projectionMatrix.multiplyToRef(
    Matrix.Translation(
      getTranslationValue(x, width),
      getTranslationValue(y, height),
      0,
    ),
    projectionMatrix,
  );
};
