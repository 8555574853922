import {
  type AbstractMesh,
  type ArcRotateCamera,
  Color3,
  Matrix,
  type PBRMaterial,
  Vector3,
} from '@babylonjs/core';
import { ok } from '@orangelv/utils';

import { getState } from '../state.js';
import type { Props, Ref, State } from '../types.js';
import {
  CustomCameraDragInput,
  CustomCameraMouseWheelInput,
} from './inputs.js';
import updateCameraFovMode from './update-camera-fov-mode.js';

const TARGET_MESH_PREFIX = 'target_';

// Preserve the rotation from Blender
// TODO [CP-1218]: extract the rotation from the target mesh instead of the camera.
const setCameraRollRotation = (
  activeCamera: ArcRotateCamera,
  camera: ArcRotateCamera,
): void => {
  const defaultUpVectorCoordinates = [0, 1, 0] as const; // Y up
  const newUpVector = new Vector3(...defaultUpVectorCoordinates);
  const rotationMatrix = new Matrix();
  camera.absoluteRotation.toRotationMatrix(rotationMatrix);
  Vector3.TransformNormalFromFloatsToRef(
    ...defaultUpVectorCoordinates,
    rotationMatrix,
    newUpVector,
  );
  activeCamera.upVector = newUpVector;
};

const positionCameraBasedOnTargetMesh = (
  activeCamera: ArcRotateCamera,
  targetMesh: AbstractMesh,
  camera: ArcRotateCamera,
): void => {
  setCameraRollRotation(activeCamera, camera);

  const boundingInfo = targetMesh.getBoundingInfo();
  const center = boundingInfo.boundingBox.centerWorld;

  const targetDimensions = boundingInfo.boundingBox.extendSize.scale(2);
  const targetDiagonalSize = Math.hypot(targetDimensions.x, targetDimensions.z);

  const distanceFactor = 0.7;
  const distanceForVisibility =
    (targetDiagonalSize / (2 * Math.tan(activeCamera.fov / 2))) *
    distanceFactor;

  const position = center.add(
    targetMesh.getFacetNormal(0).scale(-distanceForVisibility),
  );

  // Position the camera along the normal
  activeCamera.target = center;
  activeCamera.radius = distanceForVisibility;
  activeCamera.position = position;
};

const resetMouseWheelInput = (
  camera: ArcRotateCamera,
  stateRef: Ref<State>,
): void => {
  const existingInput = camera.inputs.attached['mousewheel'] as
    | CustomCameraMouseWheelInput
    | undefined;

  if (existingInput) {
    existingInput.saveOriginalState();
  } else {
    const mouseWheelInput = new CustomCameraMouseWheelInput(stateRef);
    camera.inputs.add(mouseWheelInput);
  }
};

const resetDragInput = (
  camera: ArcRotateCamera,
  stateRef: Ref<State>,
): void => {
  const existingInput = camera.inputs.attached['drag'] as
    | CustomCameraDragInput
    | undefined;

  if (existingInput) {
    existingInput.saveOriginalState();
  } else {
    const dragInput = new CustomCameraDragInput(stateRef);
    camera.inputs.add(dragInput);
  }
};

const setCamera =
  (stateRef: Ref<State>, props: Props) =>
  (cameraId: string, modelIdOrUndefined?: string): void => {
    const { config } = props;

    let modelId: string | undefined;

    if (modelIdOrUndefined === undefined) {
      const ids = Object.keys(config.models);
      [modelId] = ids;
      if (modelId === undefined) throw new Error('Logic error');
      ok(
        ids.length === 1,
        "Cannot pick which model to use because there's more than one!",
      );
    } else {
      modelId = modelIdOrUndefined;
    }

    const state = getState(stateRef);

    ok(state.scene);

    const modelState = state.models[modelId];

    if (!modelState) {
      const { activeCamera } = state.scene;
      ok(activeCamera, 'No active camera');

      return;
    }

    const { assetContainer } = modelState;

    const camera = assetContainer.cameras.find(({ id }) => id === cameraId) as
      | ArcRotateCamera
      | undefined;

    ok(camera, `Camera ${cameraId} not found on model ${modelId}!`);

    const activeCamera = state.scene.activeCamera as ArcRotateCamera | null;

    ok(activeCamera);

    activeCamera.position = camera.globalPosition.clone();
    activeCamera.rotationQuaternion = camera.absoluteRotation.clone();
    activeCamera.fov = camera.fov;
    activeCamera.minZ = camera.minZ;

    const targetMeshes = state.scene.meshes.filter((m) =>
      m.name.startsWith(TARGET_MESH_PREFIX),
    );
    for (const m of targetMeshes) m.visibility = 0;

    const cameraName = camera.name;
    const targetMeshName = `${TARGET_MESH_PREFIX}${cameraName.split('_')[0] ?? ''}`;
    const targetMesh = state.scene.meshes.find(
      (m) => m.name === targetMeshName,
    );

    if (targetMesh) {
      if (globalThis.bjsRenderer?.showCameraTargets) {
        // For debugging - show the target mesh red and semi-transparent
        targetMesh.visibility = 0.5;
        (targetMesh.material as PBRMaterial).albedoColor = new Color3(1, 0, 0);
      }

      positionCameraBasedOnTargetMesh(activeCamera, targetMesh, camera);

      resetMouseWheelInput(activeCamera, stateRef);

      resetDragInput(activeCamera, stateRef);
    }

    updateCameraFovMode(stateRef)();
  };

export default setCamera;
