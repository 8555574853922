import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import * as _ from '@technically/lodash'
import moment from 'moment-timezone'

import { t } from '../../../../platform/i18n'
import {
  saveAndOrderRecipe as saveAndOrderRecipeAction,
  saveRecipe as saveRecipeAction,
  setLayoutMode as setLayoutModeAction,
  updatePath as updatePathAction,
  modifyRecipe as modifyRecipeAction,
} from '../../../../platform/client/common/actions'
import { resetValues as resetValuesAction } from '../../../../platform/client/control-tree'
import {
  recipeIdSelector,
  isRecipeFinalizedSelector,
  isRecipeReviewSelector,
  isAppLoadingSelector,
  layoutModeSelector,
  createMenuSelector,
} from '../../../../platform/client/common/selectors'
import { push } from '../../../../platform/client/history'
import { cn, mods, states } from '../../../../platform/client/components/utils'
import ErrorAlert from '../../../../platform/client/containers/ErrorAlert'
import {
  navListSelector,
  selectableNavListSelector,
  currentNavItemSelector,
  firstWizardNavItemSelector,
  lastWizardNavItemSelector,
  prevNavItemSelector,
} from '../../../../platform/client/navigation'
import { useAppDispatch } from '../../../../platform/client/configureStore'

import { setFocusMode as setFocusModeAction } from '~rawlings/client/actions'

import controlTree from '~c/client/controlTree'
import store from '~c/client/store'
import { isFocusModeSelector, loadingTextSelector } from '../selectors'
import Finalize from './Finalize'
import Logo from './Logo'
import Navigation from './Navigation'
import OptionsBar from './OptionsBar'
import MenuBar from './MenuBar'
import ReturnToSite from './ReturnToSite'
import Wizard from './Wizard'
import Purchase from './Purchase'
import Waiting from './Waiting'
import Icon from './Icon'
import ScrollMenu from './ScrollMenu'
import CountDown from './CountDown'

import './Layout.css'

const isGloves = ['rawlings-gloves', 'easton-gloves'].includes(
  process.env.CUSTOMIZER,
)

const { returnToSiteName, returnToSiteUrl } = window.serverConfig

const menuSelector = createMenuSelector(controlTree)

const mapStateToProps = createStructuredSelector({
  isAppLoading: isAppLoadingSelector,
  loadingText: loadingTextSelector,
  isRecipeFinalized: isRecipeFinalizedSelector,
  isRecipeReview: isRecipeReviewSelector,
  nodes: controlTree.getNodes,
  recipeId: recipeIdSelector,
  navList: navListSelector,
  selectableNavList: selectableNavListSelector,
  currentNavItem: currentNavItemSelector,
  firstWizardNavItem: firstWizardNavItemSelector,
  lastWizardNavItem: lastWizardNavItemSelector,
  prevNavItem: prevNavItemSelector,
  isFocusMode: isFocusModeSelector,
  isControlTreeValid: controlTree.isValid,
  layoutMode: layoutModeSelector,
  menu: menuSelector,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setLayoutMode: (layoutMode) => dispatch(setLayoutModeAction(layoutMode)),
  openMenu: (menu) => {
    push({ query: { menu } })

    if (ownProps.onOpenMenuPost) {
      ownProps.onOpenMenuPost(menu)
    }
  },
  onChange: (path, value) => dispatch(controlTree.change(path, value)),
  onChangeWizard: (path, value) => {
    dispatch(controlTree.change(path, value))

    const state = store.getState()

    const currentNavItem = currentNavItemSelector(state)
    const lastWizardNavItem = lastWizardNavItemSelector(state)

    push({ query: { menu: currentNavItem.nextId } })

    if (currentNavItem.id === lastWizardNavItem.id) {
      dispatch(updatePathAction(controlTree))
    }
  },
  saveRecipe: () => dispatch(saveRecipeAction(controlTree)),
  saveAndOrderRecipe: () => dispatch(saveAndOrderRecipeAction(controlTree)),
  modifyRecipe: () => dispatch(modifyRecipeAction()),
  setFocusMode: (focusMode) => dispatch(setFocusModeAction(focusMode)),
  resetValues: () => dispatch(resetValuesAction()),
})

class Layout extends React.Component {
  state = {
    isNavigationOpen: false,
    toggleSearch: false,
    shouldAutoFocusSearch: false,
    countDownMs: 0,
  }

  componentDidMount = () => {
    window.addEventListener('resize', () => {
      this.detectLayoutMode()
    })
    this.detectLayoutMode()

    this.state.countDownMs = this.getCountDownMilliseconds()

    if (this.state.countDownMs > 0) {
      this.countDownIntervalId = setInterval(() => {
        this.setState((state) => ({
          ...state,
          countDownMs: this.getCountDownMilliseconds(),
        }))
      }, 1000)
    }
  }

  componentWillUnmount = () => {
    if (this.countDownIntervalId !== 0) {
      clearInterval(this.countDownIntervalId)
    }
  }

  countDownIntervalId = undefined

  getCountDownMilliseconds = () => {
    const { countDown } = this.props

    const ms = countDown ? moment(countDown.deadline).diff(moment()) : 0

    return ms
  }

  detectLayoutMode = () => {
    const layoutMode =
      window.matchMedia('(min-width: 769px)').matches ? 'desktop' : 'mobile'
    this.props.setLayoutMode(layoutMode)
  }

  openNavigation = () => {
    this.setState((state) => ({ ...state, isNavigationOpen: true }))
  }

  closeNavigation = () => {
    this.setState((state) => ({ ...state, isNavigationOpen: false }))
  }

  resetSearchState = () => {
    this.setState((state) => ({
      ...state,
      toggleSearch: false,
      shouldAutoFocusSearch: false,
    }))
  }

  onWizardChange = (path, value) => {
    this.props.onChangeWizard(path, value)
    this.resetSearchState()
  }

  static scrollToTop = () => {
    const wizardOptions = document.getElementById('wizard-options')

    if (!wizardOptions) {
      return
    }

    wizardOptions.scrollTop = 0
  }

  render() {
    const {
      currentNavItem,
      firstWizardNavItem,
      lastWizardNavItem,
      prevNavItem,
      isAppLoading,
      loadingText,
      isRecipeFinalized,
      isRecipeReview,
      nodes,
      openMenu,
      recipeId,
      Renderer,
      getProductInfo,
      isFocusMode,
      modifyRecipe,
      layoutMode,
      customComponents,
      countDown,
      logoSrc = 'logo.png',
      logoAlt = t('_rawlings:rawlingsCustomProShop'),
      extraClasses,
      getTileInfo,
      getTileExtras,
      menu,
      // New rawlings layout with a scrollable menu
      isScrollMenuLayout,
      hasFactory,
      hasUnrealRender,
    } = this.props

    const isWizard = !!(currentNavItem && currentNavItem.isWizardStep)

    const { node } = currentNavItem

    // Doesn't make much sense for TextNode, but whatev
    const onChange =
      isWizard ?
        this.onWizardChange
      : (path, value) => {
          this.props.onChange(path, value)
          if (this.props.onChangePost) {
            this.props.onChangePost(path, value)
          }
        }

    let description = null
    if (node) {
      if (!isWizard && node.object?.description) {
        description = node.object.description
      } else if (node.description) {
        description = node.description
      }
    }

    const tileSize =
      node && _.startsWith(node.tileType, 'wide') ? 'wide' : 'normal'

    const CustomComponent =
      node && node.componentName && customComponents[node.componentName]

    const baseOptionsProps = {
      layoutMode,
      onChange: node ? (x) => onChange(node.keyPath, x) : () => {},
      ...node,
      node,
      tileSize,
      description,
      openMenu,
      CustomComponent,
      isGrid: node ? node.keyPath === 'filter.size' : false,
      nodes,
      menu,
      getTileInfo,
      getTileExtras,
    }

    const isCountDownVisible = this.state.countDownMs > 0

    return (
      <>
        <CountDown
          isCountDownVisible={isCountDownVisible}
          message={countDown?.message ?? ''}
          milliseconds={this.state.countDownMs}
        />
        <div
          className={cn([
            'Layout',
            mods(isRecipeFinalized || isRecipeReview ? ['finalize'] : []),
            isWizard ? 'isWizard' : '',
            isCountDownVisible ? 'isCountDownVisible' : '',
            extraClasses,
          ])}
        >
          <ErrorAlert />
          {(
            isScrollMenuLayout &&
            !isFocusMode &&
            !(isRecipeReview || isRecipeFinalized)
          ) ?
            <MenuBar
              currentNavItem={currentNavItem}
              navList={this.props.navList}
              navigateToWizardStep={(id) => openMenu(id)}
              onCart={() => this.props.saveAndOrderRecipe()}
              onSummary={() => this.props.saveRecipe()}
              openMenu={openMenu}
              product={getProductInfo(nodes, recipeId)}
              resetValues={this.props.resetValues}
              returnToSiteName={returnToSiteName}
              returnToSiteUrl={returnToSiteUrl}
              showMenuButtons={!isWizard}
            />
          : <ReturnToSite name={returnToSiteName} url={returnToSiteUrl} />}

          {isAppLoading && <Waiting text={loadingText} />}
          {isRecipeFinalized || isRecipeReview ?
            <>
              <div key="finalize" className="Layout-finalize">
                <Finalize
                  nodes={nodes}
                  recipeId={recipeId}
                  product={getProductInfo(nodes, recipeId)}
                  isReview={isRecipeReview}
                  hasFactory={hasFactory}
                  hasUnrealRender={hasUnrealRender}
                />
              </div>
              <div key="logo" className="Layout-logo">
                <Logo
                  logoSrc={logoSrc}
                  logoAlt={logoAlt}
                  onClick={
                    isRecipeReview ? undefined : (
                      () => {
                        modifyRecipe()
                      }
                    )
                  }
                />
              </div>
              {/**
               * Quick-fix for generating previews in summary.
               * Why not `display: none`? Because it makes the page crash on iPhones.
               * */}
              <div key="renderer" style={{ visibility: 'hidden' }}>
                {this.props.isControlTreeValid && Renderer}
              </div>
            </>
          : <>
              {/* eslint-disable @typescript-eslint/no-use-before-define */}
              {isWizard ?
                <WizardLayout
                  baseOptionsProps={baseOptionsProps}
                  currentNavItem={currentNavItem}
                  firstWizardNavItem={firstWizardNavItem}
                  lastWizardNavItem={lastWizardNavItem}
                  prevNavItem={prevNavItem}
                  resetSearchState={this.resetSearchState}
                  scrollToTop={Layout.scrollToTop}
                  shouldAutoFocusSearch={this.state.shouldAutoFocusSearch}
                  toggleSearch={this.state.toggleSearch}
                  onClickSearch={() => {
                    this.setState((state) => ({
                      ...state,
                      toggleSearch: !this.state.toggleSearch,
                      shouldAutoFocusSearch: true,
                    }))
                  }}
                />
              : <CustomizerLayout
                  Renderer={Renderer}
                  baseOptionsProps={baseOptionsProps}
                  closeNavigation={this.closeNavigation}
                  currentNavItem={currentNavItem}
                  isControlTreeValid={this.props.isControlTreeValid}
                  isFocusMode={isFocusMode}
                  isNavigationOpen={this.state.isNavigationOpen}
                  navList={this.props.navList}
                  navigationComponents={this.props.navigationComponents}
                  navigationFilter={this.props.navigationFilter}
                  node={node}
                  nodes={nodes}
                  onChange={onChange}
                  openMenu={openMenu}
                  openNavigation={this.openNavigation}
                  product={getProductInfo(nodes, recipeId)}
                  resetValues={this.props.resetValues}
                  saveRecipe={this.props.saveRecipe}
                  showNavigationBackground={this.props.showNavigationBackground}
                  isScrollMenuLayout={isScrollMenuLayout}
                />
              }
              {/* eslint-enable @typescript-eslint/no-use-before-define */}
              <div key="logo" className="Layout-logo">
                <Logo
                  logoSrc={logoSrc}
                  logoAlt={logoAlt}
                  onClick={() => {
                    if (isFocusMode) {
                      this.props.setFocusMode(false)
                    } else {
                      openMenu(firstWizardNavItem.id)
                    }
                    this.resetSearchState()
                  }}
                />
              </div>
            </>
          }
        </div>
      </>
    )
  }
}

function WizardLayout({
  baseOptionsProps,
  currentNavItem,
  firstWizardNavItem,
  lastWizardNavItem,
  onClickSearch,
  prevNavItem,
  resetSearchState,
  scrollToTop,
  shouldAutoFocusSearch,
  toggleSearch,
  tileCardChildren,
}) {
  const { wizardTitle, node } = currentNavItem
  let wizardProps
  if (!node || node.isOptionsBarHidden) {
    wizardProps = baseOptionsProps
  } else if (node.nodeKind === 'SelectNode') {
    wizardProps = {
      ...baseOptionsProps,
      title: wizardTitle,
      isRequired: !currentNavItem.isWizardStepOptional,
      options: node.visibleOptions || node.options,
      isSearch: isGloves && lastWizardNavItem.id === currentNavItem.id,
      toggleSearch,
      shouldAutoFocusSearch,
      scrollToTop,
      tileCardChildren,
    }
  }
  const isFirstWizardStep = firstWizardNavItem.id === currentNavItem.id
  return (
    <div className="Layout-select">
      <div className="Layout-controls">
        {!isFirstWizardStep && (
          <div
            className="Layout-back"
            onClick={() => {
              push({ query: { menu: prevNavItem.id } })
              scrollToTop()
              resetSearchState()
            }}
          >
            <Icon name="arrow-back" />
          </div>
        )}
        {wizardProps?.isSearch && (
          <div className="Layout-search" onClick={onClickSearch}>
            <Icon name="search" />
          </div>
        )}
      </div>
      <Wizard {...wizardProps} />
    </div>
  )
}

function CustomizerLayout({
  Renderer,
  baseOptionsProps,
  closeNavigation,
  currentNavItem,
  isControlTreeValid,
  isFocusMode,
  isNavigationOpen,
  navList,
  navigationComponents,
  navigationFilter,
  node,
  nodes,
  onChange,
  openMenu,
  openNavigation,
  product,
  resetValues,
  saveRecipe,
  isScrollMenuLayout,
}) {
  let optionsBarProps
  if (!node || node.isOptionsBarHidden) {
    optionsBarProps = baseOptionsProps
  } else if (node.nodeKind === 'SelectNode') {
    optionsBarProps = {
      ...baseOptionsProps,
      isRequired: !node.isOptional,
      options:
        !node.isRequired ?
          [
            {
              id: null,
              name: t('_rawlings:none'),
            },
            ...(node.visibleOptions || node.options),
          ]
        : node.visibleOptions || node.options,
      label: node.optionName,
    }
  } else if (node.nodeKind === 'TextNode') {
    const fontNode = nodes[`${node.keyPath.slice(0, -'Text'.length)}Font`]
    optionsBarProps = {
      ...baseOptionsProps,
      input: {
        ...node,
        type: node.inputType || 'text',
        onChange: (x) => onChange(node.keyPath, x),
      },
      ...(fontNode ?
        {
          ...fontNode,
          label: '',
          isDisabled: !fontNode.isAvailable,
          onChange: (x) => onChange(fontNode.keyPath, x),
        }
      : {}),
    }
  } else if (node.nodeKind === 'FileUploadNode') {
    optionsBarProps = {
      ...baseOptionsProps,
      label: node.label,
      input: {
        value: node.value ? node.value.filename : '',
        type: 'file',
        placeholder: 'FileName.png',
        label: t('_rawlings:transparentPngFilesOnly'),
        onChange: (x) => onChange(node.keyPath, x),
      },
    }
  } else {
    throw new Error(`unrecognized nodeKind: ${node.nodeKind} ${node.keyPath}`)
  }

  const dispatch = useAppDispatch()

  return (
    <>
      {!isScrollMenuLayout && !isFocusMode && (
        <div
          key="navigation"
          className={cn([
            'Layout-navigation',
            states(isNavigationOpen ? ['open'] : []),
          ])}
        >
          <Navigation
            closeNavigation={closeNavigation}
            currentNavItem={currentNavItem}
            isOpen={isNavigationOpen}
            navList={navList}
            openMenu={openMenu}
            openNavigation={openNavigation}
            showSectionLabel={false}
            product={product}
            onCart={() => dispatch(saveAndOrderRecipeAction(controlTree))}
            onSummary={() => saveRecipe()}
            resetValues={resetValues}
            navigationFilter={navigationFilter}
            navigationComponents={navigationComponents}
          />
        </div>
      )}
      <div
        key="renderer"
        className={cn([
          'Layout-renderer',
          isScrollMenuLayout && !isFocusMode && 'mod-is-scroll-menu-layout',
        ])}
      >
        <div className="Layout-rendererBackground" />
        {isControlTreeValid && Renderer}
      </div>
      {!isFocusMode &&
        (isScrollMenuLayout ?
          <ScrollMenu
            navList={navList}
            onCart={() => dispatch(saveAndOrderRecipeAction(controlTree))}
            onChange={onChange}
            onSummary={() => saveRecipe()}
            openMenu={openMenu}
            product={product}
          />
        : <>
            <div key="optionsBar" className="Layout-optionsBar">
              {product && (
                <OptionsBar openMenu={openMenu} {...optionsBarProps} />
              )}
            </div>
            <div key="purchase" className="Layout-purchase">
              <Purchase
                isActive={
                  currentNavItem && currentNavItem.id === 'summary.purchase'
                }
                product={product}
                onCart={() => dispatch(saveAndOrderRecipeAction(controlTree))}
                onSummary={() => saveRecipe()}
              />
            </div>
          </>)}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
