import { type ArcRotateCamera, AutoRotationBehavior } from '@babylonjs/core';
import { ok } from '@orangelv/utils';

import { getState } from '../state.js';
import type { Ref, State } from '../types.js';
import calculateCameraZoomTarget from './calculate-camera-zoom-target.js';
import calculateOptimalCameraRadius from './calculate-optimal-camera-radius.js';
import setCamera from './set-camera.js';
import updateCameraFovMode from './update-camera-fov-mode.js';

const updateCamera = (stateRef: Ref<State>) => (): void => {
  const { props, scene, models } = getState(stateRef);

  const { config } = props;

  const {
    defaultCamera,
    defaultAlpha,
    defaultBeta,
    defaultRadius,
    lowerBeta,
    upperBeta,
    lowerRadius,
    upperRadius,
    minZ,
    wheelPrecision,
    pinchPrecision,
    autoRotate,
  } = config.camera ?? {};

  ok(scene);

  const camera = scene.activeCamera as ArcRotateCamera;

  const { zoomTarget, minWorld, maxWorld, radiusWorld } =
    calculateCameraZoomTarget(
      Object.values(models).map((model) => model.rootMesh),
    );

  const optimalRadius = calculateOptimalCameraRadius(
    minWorld,
    maxWorld,
    camera,
  );

  const radius = defaultRadius ?? optimalRadius;

  camera.target = zoomTarget;
  camera.radius = radius;

  camera.alpha = defaultAlpha ?? Math.PI / 2;
  camera.beta = defaultBeta ?? Math.PI / 2;

  if (lowerBeta !== undefined) {
    camera.lowerBetaLimit = lowerBeta;
  }

  if (upperBeta !== undefined) {
    camera.upperBetaLimit = upperBeta;
  }

  camera.lowerRadiusLimit = lowerRadius ?? radiusWorld.length() + camera.minZ;

  if (upperRadius !== undefined) {
    camera.upperRadiusLimit = upperRadius;
  }

  const radiusDelta =
    lowerRadius !== undefined && upperRadius !== undefined ?
      upperRadius - lowerRadius
    : undefined;

  if (minZ !== undefined) {
    camera.minZ = minZ;
  } else if (radiusDelta !== undefined) {
    camera.minZ = radiusDelta < 10 ? 0.1 : 1;
  }

  camera.wheelPrecision = wheelPrecision ?? (100 / radius) * 10;

  camera.pinchPrecision = pinchPrecision ?? (100 / radius) * 10;

  if (defaultCamera !== undefined) {
    if (typeof defaultCamera === 'string') {
      setCamera(stateRef, props)(defaultCamera);
    } else {
      setCamera(stateRef, props)(...defaultCamera);
    }
  }

  updateCameraFovMode(stateRef)();

  // Store the default camera config which can later be restored by pressing
  // Space.
  camera.storeState();

  let autoRotationBehavior = camera.getBehaviorByName('AutoRotation') as
    | AutoRotationBehavior
    | undefined;

  if (autoRotate && !autoRotationBehavior) {
    autoRotationBehavior = new AutoRotationBehavior();

    autoRotationBehavior.zoomStopsAnimation = true;
    autoRotationBehavior.idleRotationSpeed = 0.1;
    autoRotationBehavior.idleRotationWaitTime = 5000;
    autoRotationBehavior.idleRotationSpinupTime = 1000;

    // https://forum.babylonjs.com/t/autorotationbehavior-idlerotationwaittime-and-start-of-scene/14219
    setTimeout(() => {
      const nextState = getState(stateRef);

      if (!autoRotationBehavior || !nextState.props.config.camera?.autoRotate) {
        return;
      }

      camera.addBehavior(autoRotationBehavior);
    }, autoRotationBehavior.idleRotationWaitTime);
  } else if (!autoRotate && autoRotationBehavior) {
    camera.removeBehavior(autoRotationBehavior);
  }

  camera.attachControl(false);
};

export default updateCamera;
