import {
  Animation,
  type ArcRotateCamera,
  CubicEase,
  EasingFunction,
  Vector3,
} from '@babylonjs/core';
import { ok } from '@orangelv/utils';

const ANIMATION_SPEED = 300;
const ANIMATION_FPS = 60;
const ANIMATION_DURATION = 120;

const animateCameraProperties = (
  camera: ArcRotateCamera,
  animationConfig: Partial<Record<keyof ArcRotateCamera, unknown>>,
  onAnimationEnd?: () => void,
  easingMode = EasingFunction.EASINGMODE_EASEINOUT,
  speedMultiplier = 1,
): void => {
  const ease = new CubicEase();
  ease.setEasingMode(easingMode);

  const animations = Object.entries(animationConfig).map(
    ([property, value]) => {
      const animation = new Animation(
        `Camera_${property}`,
        property,
        ANIMATION_FPS,
        value instanceof Vector3 ?
          Animation.ANIMATIONTYPE_VECTOR3
        : Animation.ANIMATIONTYPE_FLOAT,
        Animation.ANIMATIONLOOPMODE_CONSTANT,
      );

      animation.setKeys([
        {
          frame: 0,
          value: camera[property as keyof ArcRotateCamera] as number | Vector3,
        },
        { frame: ANIMATION_DURATION, value },
      ]);

      animation.setEasingFunction(ease);

      return animation;
    },
  );

  camera
    .getScene()
    .beginDirectAnimation(
      camera,
      animations,
      0,
      ANIMATION_DURATION,
      false,
      (ANIMATION_SPEED * speedMultiplier) / ANIMATION_DURATION,
      () => {
        if (onAnimationEnd) onAnimationEnd();
      },
    );
};

const animateRadius = (
  camera: ArcRotateCamera,
  targetRadius: number,
  onAnimationEnd?: () => void,
  speedMultiplier = 1,
): void => {
  const ease = new CubicEase();
  ease.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);

  Animation.CreateAndStartAnimation(
    'Camera_radius',
    camera,
    'radius',
    ANIMATION_SPEED * speedMultiplier,
    ANIMATION_DURATION,
    camera.radius,
    targetRadius,
    Animation.ANIMATIONLOOPMODE_CONSTANT,
    ease,
    onAnimationEnd,
  );
};

const GLOBAL_CAMERA_CONFIG = {
  target: Vector3.Zero(),
  radius: 1,
  fov: 0.35,
  upVector: new Vector3(0, 1, 0),
};

export type CameraState = {
  target: Vector3 | null;
  radius: number | null;
  fov: number | null;
  upVector: Vector3 | null;
  hasRestoredOriginal: boolean;
};

export function transitionToGlobalView(
  camera: ArcRotateCamera,
  transitionRadius: boolean,
  onAnimationEnd?: () => void,
): void {
  const transitionConfig = {
    target: GLOBAL_CAMERA_CONFIG.target,
    fov: GLOBAL_CAMERA_CONFIG.fov,
    upVector: GLOBAL_CAMERA_CONFIG.upVector,
  };

  if (transitionRadius) {
    // When transitioning radius, there are 2 animations, but we want the duration to be the same.
    const animationSpeedMultiplier = 2;

    animateCameraProperties(
      camera,
      transitionConfig,
      (): void => {
        animateRadius(
          camera,
          GLOBAL_CAMERA_CONFIG.radius,
          onAnimationEnd,
          animationSpeedMultiplier,
        );
      },
      EasingFunction.EASINGMODE_EASEIN,
      animationSpeedMultiplier,
    );
  } else {
    animateCameraProperties(camera, transitionConfig, onAnimationEnd);
  }
}

export function transitionToOriginalView(
  camera: ArcRotateCamera,
  originalState: CameraState,
  transitionRadius: boolean,
  onComplete?: () => void,
): void {
  ok(originalState.target);
  ok(originalState.fov !== null);
  ok(originalState.upVector);

  const transitionConfig = {
    target: originalState.target,
    fov: originalState.fov,
    upVector: originalState.upVector,
  };

  if (transitionRadius) {
    // When transitioning radius, there are 2 animations, but we want the duration to be the same.
    const animationSpeedMultiplier = 2;

    animateCameraProperties(
      camera,
      transitionConfig,
      (): void => {
        ok(originalState.radius !== null);
        animateRadius(
          camera,
          originalState.radius,
          onComplete,
          animationSpeedMultiplier,
        );
      },
      EasingFunction.EASINGMODE_EASEIN,
      animationSpeedMultiplier,
    );
  } else {
    animateCameraProperties(camera, transitionConfig, onComplete);
  }
}
