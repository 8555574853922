import {
  ArcRotateCamera,
  ArcRotateCameraPointersInput,
  Vector3,
} from '@babylonjs/core';
import { ok } from '@orangelv/utils';

import { getState } from '../state.js';
import type { Ref, State } from '../types.js';
import {
  CustomCameraKeyboardMoveInput,
  CustomCameraMouseWheelInput,
} from './inputs.js';
import { applyCameraProjectionTranslation } from './update-camera-projection-matrix.js';

const createCamera = (stateRef: Ref<State>) => (): void => {
  const state = getState(stateRef);

  const { scene, emptyScene, props } = state;

  ok(scene);
  ok(emptyScene);

  const camera = new ArcRotateCamera(
    'myCamera',
    0,
    0,
    0,
    Vector3.Zero(),
    scene,
  );

  camera.useInputToRestoreState = false;

  const { inputs } = camera;

  inputs.clear();

  const keyboardMoveInput = new CustomCameraKeyboardMoveInput();

  keyboardMoveInput.keysLeft = [39];
  keyboardMoveInput.keysRight = [37];
  keyboardMoveInput.keysUp = [40];
  keyboardMoveInput.keysDown = [38];
  keyboardMoveInput.keysReset = [32];

  inputs.add(keyboardMoveInput);

  const pointersInput = new ArcRotateCameraPointersInput();

  inputs.add(pointersInput);

  const mouseWheelInput = new CustomCameraMouseWheelInput(stateRef);

  inputs.add(mouseWheelInput);

  camera.onRestoreStateObservable.add(() => {
    const existingInput = camera.inputs.attached['mousewheel'] as
      | CustomCameraMouseWheelInput
      | undefined;

    if (existingInput) {
      existingInput.saveOriginalState();
    } else {
      const newMouseWheelInput = new CustomCameraMouseWheelInput(stateRef);
      camera.inputs.add(newMouseWheelInput);
    }
  });

  // Disables panning.
  camera.panningSensibility = 0;

  scene.onBeforeRenderObservable.add(() => {
    const projectionTranslation = props.config.camera?.translateProjection;
    if (projectionTranslation) {
      applyCameraProjectionTranslation(camera, projectionTranslation);
    }
  });

  emptyScene.activeCamera = camera;
};

export default createCamera;
